import {
    ApiDailyCheckinAnswer,
    ApiDailyJourney,
    ApiOnboardingAnswer,
    ApiCompany,
    FormName,
    GetCompanyBenefitsInfoResponse,
    User,
    RealmDiscovery,
    ApiLiveStream,
    ApiComment,
    ApiMonthlyActivity,
    SaveActivity,
    SearchResultContentItem,
    ApiMonthlyCheckinAnswerV101,
    ApiResourcesContentDivision,
    ApiResourcesContentModule,
    ApiResourcesContentCategory,
    ApiResourcesContentLanguage,
    ApiResourcesContentFeaturedArticle,
    ApiTeacher,
    ApiLandingPage,
    ApiClinician,
    VideoPlaylistResponse,
    ApiUser,
    ApiDirectoryNode,
    ApiInvitation,
    ApiCountry,
    AvailableContentTypes,
    ApiAsset,
    ApiMedia,
    ContentItem, 
    DeleteVideoContentResponse, 
    SaveVideoContentResponse, 
    VideoCategoryResponse, 
    VideoResponse,
    ApiArticle
} from "./ApiTypes";
import * as axios from 'axios';
import { AxiosInstance, AxiosRequestConfig } from "axios";
import ClientConfig from "./ClientConfig";
import { URLSearchParams } from "url";

const AUTH_TOKEN = "auth_token";
const REDIRECT_URL = "journey_redirect_url";

class ApiServiceImpl {

    private readonly apiRoot: string;
    private readonly axios: AxiosInstance;

    constructor() {
        this.axios = axios.default.create();
        this.apiRoot = ClientConfig.apiRoot;
        this.axios.interceptors.response.use(
            (response: any) => response,
            (error: any) => this.errorFormatter(error));
    }

    buildQueryString(params: any): string {
        return new URLSearchParams(params).toString();
    }

    setLanguage(language: ApiResourcesContentLanguage | null): void {
        if (!language) {
            delete this.axios.defaults.headers.common['x-locale'];
        } else {
            this.axios.defaults.headers.common['x-locale'] = language.abbreviation;
        }
    }

    registerUser(userData: Partial<User>) {
        return this.axios.post(`${this.apiRoot}/users`, userData)
            .then(response => response.data);
    }

    getCompany(companyCode: string): Promise<ApiCompany> {
        return this.axios.get<ApiCompany>(`${this.apiRoot}/companies/${companyCode}`)
            .then(response => response.data);
    }

    getCompanies(): Promise<ApiCompany[]> {
        return this.axios.get<ApiCompany[]>(`${this.apiRoot}/companies`)
            .then(response => response.data);
    }

    getCompanyCountries(companyCode: string): Promise<ApiCountry[]> {
        return this.axios.get<ApiCountry[]>(`${this.apiRoot}/companies/countries/${companyCode}`, this.getHttpConfig())
            .then(response => response.data)
    }

    saveDailyCheckinAnswers(data: ApiDailyCheckinAnswer): Promise<void> {
        return this.axios.post(`${this.apiRoot}/checkin/daily`, data, this.getHttpConfig())
            .then(response => response.data);
    }

    saveDailyCheckinAnswersV2(data: ApiDailyCheckinAnswer, timezoneOffsetInMilliseconds: number): Promise<void> {
        return this.axios.post(`${this.apiRoot}/checkin/daily/V2`, { data, timezoneOffsetInMilliseconds }, this.getHttpConfig())
            .then(response => response.data);
    }

    saveOnBoardingAnswers(data: ApiOnboardingAnswer): Promise<void> {
        return this.axios.post(`${this.apiRoot}/checkin/onboarding`, data, this.getHttpConfig())
            .then(response => response.data);
    }

    getCurrentAssessment(): Promise<FormName> {
        return this.axios.get<{ code: FormName }>(`${this.apiRoot}/checkin/current`, this.getHttpConfig())
            .then(response => response.data.code);
    }

    getMonthlyAssessmentV101(): Promise<ApiMonthlyCheckinAnswerV101> {
        return this.axios.get<ApiMonthlyCheckinAnswerV101>(`${this.apiRoot}/checkin/monthly/V101`, this.getHttpConfig())
            .then(response => response.data);
    }

    saveMonthlyAssessmentV101(data: any): Promise<void> {
        return this.axios.post(`${this.apiRoot}/checkin/monthly/V101`, data, this.getHttpConfig())
            .then(response => response.data);
    }

    saveUserCalendarEmailService(emailService: string): Promise<void> {
        return this.axios.put(`${this.apiRoot}/users/me/calendar-email-service`, { emailService }, this.getHttpConfig())
            .then(response => response.data);
    }

    saveUserName(firstName: string, lastName: string): Promise<void> {
        return this.axios.put(`${this.apiRoot}/users/me/name`, { firstName, lastName }, this.getHttpConfig())
            .then(response => response.data);
    }

    saveUserProfileImage(file: FormData): Promise<void> {
        return this.axios.put(`${this.apiRoot}/users/me/profile-image`, file, this.getHttpConfig())
            .then(response => response.data);
    }

    savePreferredEmail(preferredEmail: string): Promise<void> {
        return this.axios.put(`${this.apiRoot}/users/me/preferred-email`, { preferredEmail }, this.getHttpConfig())
            .then(response => response.data);
    }

    inviteFriendAndFamily(email: string, firstName: string, lastName: string, message: string | null): Promise<void> {
        return this.axios.post(`${this.apiRoot}/invite`, { email, firstName, lastName, message }, this.getHttpConfig())
            .then(response => response.data);
    }

    resendInviteFriendFamilyEmail(inviteeId: number): Promise<void> {
        return this.axios.post(`${this.apiRoot}/resend-invite`, { inviteeId }, this.getHttpConfig())
            .then(response => response.data);
    }

    saveUserCareer(career: string): Promise<void> {
        return this.axios.put(`${this.apiRoot}/users/me/career`, { career }, this.getHttpConfig())
            .then(response => response.data);
    }

    saveUserInterests(interests: string): Promise<void> {
        return this.axios.put(`${this.apiRoot}/users/me/interests`, { interests }, this.getHttpConfig())
            .then(response => response.data);
    }

    saveUserDivisions(divisions: any): Promise<void> {
        return this.axios.put(`${this.apiRoot}/users/me/divisions`, { ...divisions }, this.getHttpConfig())
            .then(response => response.data);
    }

    getCurrentUser(): Promise<ApiUser> {
        return this.axios.get<ApiUser>(`${this.apiRoot}/users/me`, this.getHttpConfig())
            .then(response => response.data);
    }

    getUserInvitation(): Promise<ApiInvitation[]> {
        return this.axios.get<ApiInvitation[]>(`${this.apiRoot}/users/me/invitations`, this.getHttpConfig())
            .then(response => response.data);
    }

    logout(): Promise<any> {
        return this.axios.get<any>(`${this.apiRoot}/users/logout`, this.getHttpConfig())
            .then(response => response.data);
    }

    setAuthCredentials(token: string): void {
        return localStorage.setItem(AUTH_TOKEN, token);
    };

    getAuthCredentials(): string | null {
        return localStorage.getItem(AUTH_TOKEN);
    };

    setRedirectUrl(url: string): void {
        return localStorage.setItem(REDIRECT_URL, url);
    };

    getRedirectUrl(): string | null {
        return localStorage.getItem(REDIRECT_URL);
    };

    clearRedirectUrl(): void {
        return localStorage.removeItem(REDIRECT_URL);
    };

    sendLoginEmail(email: string) {
        return this.axios.post(`${this.apiRoot}/login`, { email })
            .then(response => response.data);
    };

    sendCodeLoginEmail(email: string) {
        return this.axios.post(`${this.apiRoot}/code-login`, { email })
            .then(response => response.data);
    };

    verifyCodeLogin(email: string, code: number) {
        return this.axios.post(`${this.apiRoot}/code-login/verify`, { email, code },{withCredentials:true})
            .then(response => response);
    };

    getWorkOsRedirectUrl(domain: string | null, redirectUri: string, companyId: number | null): Promise<string> {
        const encodedUrl = encodeURIComponent(redirectUri);
        return this.axios.get<string>(`${this.apiRoot}/code-login/workos/auth?domain=${domain}&redirectUri=${encodedUrl}&companyId=${companyId}`)
            .then(response => response.data);
    };

    getWorkOsProfile(code: string): Promise<string> {
        return this.axios.get<string>(`${this.apiRoot}/code-login/workos/auth/callback?code=${code}`)
            .then(response => response.data);
    };

    deleteJourneyUserCheckinAnswers() {
        return this.axios.delete(`${this.apiRoot}/checkin`, this.getHttpConfig())
            .then(response => response.data);
    };

    submitResourcesAskQuestionForm(data: any) {
        return this.axios.post(`${this.apiRoot}/eap/askquestion`, data, this.getHttpConfig())
            .then(response => response.data);
    };   

    getClinicalCareModalStatus(): Promise<boolean> {
        return this.axios.get<boolean>(`${this.apiRoot}/eap/clinical-care/checkin`, this.getHttpConfig())
            .then(response => response.data);
    }

    logClinicalCareCheckin(checkinRating: number): Promise<boolean> {
        return this.axios.post<boolean>(`${this.apiRoot}/eap/clinical-care/checkin`, {checkinRating}, this.getHttpConfig())
            .then(response => response.data);
    };    
    
    logClinicalCareMonthlyAssessment(data: ApiMonthlyCheckinAnswerV101): Promise<boolean> {
        return this.axios.post<boolean>(`${this.apiRoot}/eap/clinical-care/assessment`, {data}, this.getHttpConfig())
            .then(response => response.data);
    };   
    
    logClinicalCareVideoWatch(playlistId: string | undefined, videoId: string): Promise<void> {
        return this.axios.post<void>(`${this.apiRoot}/eap/clinical-care/video`, {videoId, playlistId}, this.getHttpConfig())
            .then(response => response.data);
    };

    getAvailableContentTypes(): Promise<AvailableContentTypes> {
        return this.axios.get(`${this.apiRoot}/users/me/available-content-types`, this.getHttpConfig())
            .then(response => response.data)
    };

    getCompanyBenefitsInfo(): Promise<GetCompanyBenefitsInfoResponse> {
        return this.axios.get<GetCompanyBenefitsInfoResponse>(`${this.apiRoot}/users/me/benefits`, this.getHttpConfig())
            .then(response => response.data);
    }

    getDailyJourneyInfo(): Promise<ApiDailyJourney> {
        return this.axios.get<ApiDailyJourney>(`${this.apiRoot}/users/me/daily-journey`, this.getHttpConfig())
            .then(response => response.data);
    }

    getOlderDailyJourneyInfo(count: string, startingDate: Date): Promise<ApiDailyJourney[]> {
        return this.axios.get<ApiDailyJourney[]>(`${this.apiRoot}/users/me/daily-journey/older/${count}/${startingDate}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getNewerDailyJourneyInfo(count: string, startingDate: Date): Promise<ApiDailyJourney[]> {
        return this.axios.get<ApiDailyJourney[]>(`${this.apiRoot}/users/me/daily-journey/newer/${count}/${startingDate}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getDailyJourneyById(id: string): Promise<ApiDailyJourney> {
        return this.axios.get<ApiDailyJourney>(`${this.apiRoot}/daily-journey/${id}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getDefaultRecommendations(): Promise<ContentItem[]> {
        return this.axios.get<ContentItem[]>(`${this.apiRoot}/default-recommendations`)
            .then(response => response.data);
    }

    getIonicRecommendations(): Promise<ContentItem[]> {
        return this.axios.get<ContentItem[]>(`${this.apiRoot}/users/me/ionic-recommendations`, this.getHttpConfig())
            .then(response => response.data);
    }

    userMarketingOptIn(): Promise<any> {
        return this.axios.post(`${this.apiRoot}/marketing-opt-in`, null, this.getHttpConfig())
            .then(response => response.data);
    }

    getCategory(id: string): Promise<VideoCategoryResponse> {
        return this.axios.get<VideoCategoryResponse>(`${this.apiRoot}/vimeo/category/${id}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getPlaylist(id: string): Promise<VideoPlaylistResponse> {
        return this.axios.get<any>(`${this.apiRoot}/vimeo/playlist/${id}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getRegistrationPageVideos(id: number): Promise<VideoPlaylistResponse> {
        return this.axios.get<any>(`${this.apiRoot}/registration/playlist/${id}`, this.getHttpConfig())
            .then(response => response.data);
    }

    /**
     * @param options.excludeLiveCategory if true, the response will not include the category corresponding to live streams
     */
    getHomeContent(options: any = {}): Promise<VideoCategoryResponse[]> {
        var requestUrl = `${this.apiRoot}/video/home`;
        if (options.excludeLiveCategory === true) {
            requestUrl = requestUrl + "?excludeLiveCategory=1";
        }
        return this.axios.get<any>(requestUrl, this.getHttpConfig())
            .then(response => response.data);
    }

    getContinueWatching(): Promise<ContentItem[]> {
        return this.axios.get<ContentItem[]>(`${this.apiRoot}/videoactivity/continuewatching`, this.getHttpConfig())
            .then(response => response.data);
    }

    getSavedVideos(): Promise<ContentItem[]> {
        return this.axios.get<ContentItem[]>(`${this.apiRoot}/savedcontent`, this.getHttpConfig())
            .then(response => response.data);
    }

    getNextVideoInPlaylist(videoId: number, playlistId: number): Promise<VideoResponse> {
        return this.axios.get<VideoResponse>(`${this.apiRoot}/video/next/${playlistId}/${videoId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getNextVideoInAnyPlaylist(videoId: number): Promise<VideoResponse> {
        return this.axios.get<VideoResponse>(`${this.apiRoot}/video/next/${videoId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getLiveStreamContent(): Promise<ContentItem[]> {
        return this.axios.get<ContentItem[]>(`${this.apiRoot}/content/home/live-streams`, this.getHttpConfig())
            .then(response => response.data);
    }

    getLiveStreamComments(liveStreamId: number): Promise<ApiComment[]> {
        return this.axios.get<ApiComment[]>(`${this.apiRoot}/live-stream/comments/${liveStreamId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    createLiveStreamComment(comment: string, liveStreamId: number): Promise<any> {
        return this.axios.post<any>(`${this.apiRoot}/live-stream/comment`, { comment, liveStreamId }, this.getHttpConfig())
            .then(response => response.data);
    }

    getVideoComments(videoId: number): Promise<ApiComment[]> {
        return this.axios.get<ApiComment[]>(`${this.apiRoot}/vimeo/comments/${videoId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    createVideoComment(comment: string, videoId: number): Promise<any> {
        return this.axios.post<any>(`${this.apiRoot}/comment`, { comment, videoId }, this.getHttpConfig())
            .then(response => response.data);
    }

    reportComment(commentId: number): Promise<any> {
        return this.axios.put<any>(`${this.apiRoot}/comment/report`, { commentId }, this.getHttpConfig())
            .then(response => response.data);
    }

    getDailyVideoInfo(): Promise<any> {
        return this.axios.get<any>(`${this.apiRoot}/users/me/daily-video`, this.getHttpConfig())
            .then(response => response.data);
    }

    getLiveStreamInfo(id: number): Promise<ApiLiveStream> {
        return this.axios.get<ApiLiveStream>(`${this.apiRoot}/live-stream/${id}`, this.getHttpConfig())
            .then(response => response.data)
    }

    async getDailyPractice(): Promise<VideoResponse> {
        const response = await this.axios.get<VideoResponse>(`${this.apiRoot}/video/daily-practice`, this.getHttpConfig());
        return response.data;
    }

    async getTodaysActivity(): Promise<ApiMonthlyActivity> {
        const response = await this.axios.get<ApiMonthlyActivity>(`${this.apiRoot}/monthlyactivity/today`, this.getHttpConfig());
        return response.data;
    }

    async getTodaysActivityV2(timezoneOffsetInMilliseconds: number): Promise<ApiMonthlyActivity> {
        const response = await this.axios.get<ApiMonthlyActivity>(`${this.apiRoot}/monthlyactivity/today/${timezoneOffsetInMilliseconds}`, this.getHttpConfig());
        return response.data;
    }

    async getMonthlyActivity(): Promise<ApiMonthlyActivity[]> {
        const response = await this.axios.get<ApiMonthlyActivity[]>(`${this.apiRoot}/monthlyactivity/month`, this.getHttpConfig());
        return response.data;
    }

    async recordActivity(type: 'visit' | 'daily-journey-read' | 'video-watch' | 'daily-practice-watch' | 'checkin' | 'audio-listen'): Promise<void> {
        await this.axios.post<SaveActivity>(`${this.apiRoot}/monthlyactivity`, { type }, this.getHttpConfig());
    }

    async getLastThirtyDailyCheckins(): Promise<Array<ApiDailyCheckinAnswer | null>> {
        const response = await this.axios.get<Array<ApiDailyCheckinAnswer | null>>(`${this.apiRoot}/checkin/last-thirty`, this.getHttpConfig());
        return response.data;
    }

    async getLastSelectedDailyCheckins(selectedAmount: number): Promise<Array<ApiDailyCheckinAnswer | null>> {
        const response = await this.axios.get<Array<ApiDailyCheckinAnswer | null>>(`${this.apiRoot}/checkin/last/${selectedAmount}`, this.getHttpConfig());
        return response.data;
    }

    async demoReset(): Promise<any> {
        const response = await this.axios.get<null>(`${this.apiRoot}/demo-reset/reset`, this.getHttpConfig());
        return response.data;
    }

    private getHttpConfig(): AxiosRequestConfig {
        const token = localStorage.getItem(AUTH_TOKEN);
        const axiosConfig: AxiosRequestConfig = {};
        axiosConfig.withCredentials = true;
        if (token) {
            axiosConfig.headers = {
                "Authorization": `Bearer ${token}`
            }
        }
        return axiosConfig;
    };

    /**
     * @param {string} appDevice - 'ios' or 'android' used to determine the number of search results to return
     * @param {string} title - the title to search for
     * @param {string} arrayOfIdsToSearchWithin - List of media/collection Ids formatted for Algolia to search within this specific media or collections.
     * @returns {Promise<SearchResultContentItem[]>}
    */
    searchByTitle(title: string, appDevice: string, arrayOfIdsToSearchWithin?: string): Promise<SearchResultContentItem[]> {
        const params = {
            search: title,
            appDevice: appDevice,
            contentIds: arrayOfIdsToSearchWithin
        }
        return this.axios.get<SearchResultContentItem[]>(
            `${this.apiRoot}/content?${this.buildQueryString(params)}`,
            this.getHttpConfig()
        ).then(response => response.data);
    }

    getRealmDiscovery(email: string): Promise<RealmDiscovery> {
        return this.axios.get<RealmDiscovery>(`${this.apiRoot}/users/realm-discovery/${email}`)
            .then(response => response.data);
    }

    deleteUser(): Promise<void> {
        return this.axios.delete(`${this.apiRoot}/users/me`, this.getHttpConfig())
            .then(response => response.data);
    }

    getVideoById(id: string): Promise<VideoResponse> {
        return this.axios.get<VideoResponse>(`${this.apiRoot}/watch/video/${id}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getPublicVideoById(id: string): Promise<VideoResponse> {
        return this.axios.get<VideoResponse>(`${this.apiRoot}/video/public/${id}`, this.getHttpConfig())
            .then(response => response.data);
    }

    loginWithPassword(email: string, password: string): Promise<any> {
        return this.axios.post<any>(`${this.apiRoot}/passwordlogin`, { email, password }, this.getHttpConfig())
            .then(response => response.data);
    }

    saveVideo(videoId: number): Promise<SaveVideoContentResponse> {
        return this.axios.put<SaveVideoContentResponse>(`${this.apiRoot}/savedcontent`, {
            videoId
        }, this.getHttpConfig())
            .then(response => response.data);
    }

    saveVideoPlaylist(playlistId: number): Promise<SaveVideoContentResponse> {
        return this.axios.put<SaveVideoContentResponse>(`${this.apiRoot}/savedcontent`, {
            playlistId
        }, this.getHttpConfig())
            .then(response => response.data);
    }

    removeSavedVideo(videoId: number): Promise<DeleteVideoContentResponse> {
        return this.axios.delete<DeleteVideoContentResponse>(`${this.apiRoot}/savedcontent/video/${videoId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    removeSavedVideoPlaylist(playlistId: number): Promise<DeleteVideoContentResponse> {
        return this.axios.delete<DeleteVideoContentResponse>(`${this.apiRoot}/savedcontent/playlist/${playlistId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    logVideoWatch(videoId: number, position: number, duration: number): Promise<void> {
        return this.axios.put(`${this.apiRoot}/videoactivity/watch`, {
            videoId,
            position,
            duration
        }, this.getHttpConfig())
            .then(response => response.data);
    }

    getZoomAppHomeContent(): Promise<ContentItem[]> {
        return this.axios.get<ContentItem[]>(`${this.apiRoot}/zoomapp/home/content`, this.getHttpConfig())
            .then(response => response.data);
    }    

    getAsset(id: string): Promise<ApiAsset> {
        return this.axios.get<ApiAsset>(`${this.apiRoot}/assets/${id}`, this.getHttpConfig())
            .then(response => response.data)
    }


    getMedia(id: string): Promise<ApiMedia> {
        return this.axios.get<ApiMedia>(`${this.apiRoot}/assets/media/${id}`, this.getHttpConfig())
            .then(response => response.data)
    }

    getAssets(listType: string): Promise<ApiAsset[]> {
        return this.axios.get<ApiAsset[]>(`${this.apiRoot}/assets?type=${listType}`, this.getHttpConfig())
            .then(response => response.data)
    }

    setUserHasMobileApp(): Promise<any> {
        return this.axios.post(`${this.apiRoot}/users/me/installed-app`, null, this.getHttpConfig())
            .then(response => response.data);
    }

    setUserAddedMindfulBreak(): Promise<any> {
        return this.axios.post(`${this.apiRoot}/users/me/added-mindful-break`, null, this.getHttpConfig())
            .then(response => response.data);
    }

    setUserHasSeenMonthlyAssessment(): Promise<any> {
        return this.axios.post(`${this.apiRoot}/users/me/seen-monthly-assessment`, null, this.getHttpConfig())
            .then(response => response.data);
    }

    setUserHasZoomApp(): Promise<any> {
        return this.axios.post(`${this.apiRoot}/users/me/installed-zoom-app`, null, this.getHttpConfig())
    }

    setUserHasTeamsApp(): Promise<any> {
        return this.axios.post(`${this.apiRoot}/users/me/installed-teams-app`, null, this.getHttpConfig())
    }

    setUserHasSharedRegistrationLink(): Promise<any> {
        return this.axios.post(`${this.apiRoot}/users/me/shared-registration-link`, null, this.getHttpConfig())
    }

    setUserAsMhamChampion(): Promise<any> {
        return this.axios.post(`${this.apiRoot}/users/me/become-mham-champion`, null, this.getHttpConfig())
    }

    setUserAsMhamParticipant(champion?: string): Promise<any> {
        return this.axios.post(`${this.apiRoot}/users/me/become-mham-participant`, { champion }, this.getHttpConfig())
    }

    signupToEvent(email: string, eventName: string, companyCode: string) {
        return this.axios.post(`${this.apiRoot}/code-login/eventsignup`, { eventName, email, companyCode })
            .then(response => response.data);
    }

    getLandingPage(name: string): Promise<ApiLandingPage> {
        return this.axios.get<ApiLandingPage>(`${this.apiRoot}/landing/${name}`, this.getHttpConfig())
            .then(response => response.data)
    }

    //----------------------------------- Resources --------------------------------------------------


    getResourcesLanguages(companyId?: number): Promise<ApiResourcesContentLanguage[]> {
        return this.axios.get<ApiResourcesContentLanguage[]>(`${this.apiRoot}/resources/languages?companyId=${companyId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getResourcesDefaultLanguage(): Promise<ApiResourcesContentLanguage> {
        return this.axios.get<ApiResourcesContentLanguage>(`${this.apiRoot}/resources/languages/default`, this.getHttpConfig())
            .then(response => response.data);
    }

    getResourcesLanguage(languageId: string): Promise<ApiResourcesContentLanguage> {
        return this.axios.get<ApiResourcesContentLanguage>(`${this.apiRoot}/resources/languages/${languageId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getResourcesContentDivisions(): Promise<ApiResourcesContentDivision[]> {
        return this.axios.get<ApiResourcesContentDivision[]>(`${this.apiRoot}/resources/divisions`, this.getHttpConfig())
            .then(response => response.data);
    }

    getResourcesContentModuleByDivisionId(divisionId: string): Promise<ApiResourcesContentModule[]> {
        return this.axios.get<ApiResourcesContentModule[]>(`${this.apiRoot}/resources/modules?divisionId=${divisionId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getResourcesContentByDivisionId(divisionId: string): Promise<ApiResourcesContentDivision> {
        return this.axios.get<ApiResourcesContentDivision>(`${this.apiRoot}/resources/divisions/${divisionId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getResourcesContentCategoriesByModuleId(moduleId: string): Promise<ApiResourcesContentCategory[]> {
        return this.axios.get<ApiResourcesContentCategory[]>(`${this.apiRoot}/resources/categories?moduleId=${moduleId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getResourcesContentArticleByCategoryId(categoriesIdStringedArray: string): Promise<ApiArticle[]> {
        return this.axios.get<ApiArticle[]>(`${this.apiRoot}/resources/articles?categoryId=${categoriesIdStringedArray}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getResourcesContentModule(moduleId: string): Promise<ApiResourcesContentModule> {
        return this.axios.get<ApiResourcesContentModule>(`${this.apiRoot}/resources/modules/${moduleId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getResourcesContentArticle(articleId: string): Promise<ApiArticle> {
        return this.axios.get<ApiArticle>(`${this.apiRoot}/resources/articles/${articleId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getResourcesContentFeaturedArticle(): Promise<ApiResourcesContentFeaturedArticle[]> {
        return this.axios.get<ApiResourcesContentFeaturedArticle[]>(`${this.apiRoot}/resources/articles/featured`, this.getHttpConfig())
            .then(response => response.data);
    }

    getWorklifeContentDivisions(): Promise<ApiResourcesContentDivision[]> {
        return this.axios.get<ApiResourcesContentDivision[]>(`${this.apiRoot}/resources/divisions/worklife`, this.getHttpConfig())
            .then(response => response.data);
    }


    //----------------------------------- Clinicians --------------------------------------------------

    getClinicians(): Promise<ApiClinician[]> {
        return this.axios.get<ApiClinician[]>(`${this.apiRoot}/scheduling/clinicians`, this.getHttpConfig())
            .then(response => response.data)
    }

    getCliniciansV2(now: string | null): Promise<ApiClinician[]> {
        return this.axios.get<ApiClinician[]>(`${this.apiRoot}/scheduling/clinicians/${now}`, this.getHttpConfig())
            .then(response => response.data)
    }

    createClinicianAppointment(clinicianId: number, appointmentTime: string): Promise<any> {
        return this.axios.post(`${this.apiRoot}/scheduling`, { clinicianId, appointmentTime, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }, this.getHttpConfig())
    }

    getScheduledAppointment(date: string): Promise<any> {
        return this.axios.get<ApiClinician[]>(`${this.apiRoot}/scheduling/${date}`, this.getHttpConfig())
            .then(response => response.data)
    }

    cancelAppointment(id: number): Promise<any> {
        return this.axios.put(`${this.apiRoot}/scheduling/cancel`, { id }, this.getHttpConfig()).then(response => response.data)
    }

    //----------------------------------- WPO Clinicians --------------------------------------------------

    getWpoClinicians(timezone?: string | null): Promise<ApiClinician[]> {
        return this.axios.get<ApiClinician[]>(`${this.apiRoot}/scheduling/wpo/clinicians?timezone=${timezone ?? 'Etc/GMT'}`, this.getHttpConfig())
            .then(response => response.data)
    }

    createWpoClinicianAppointment(clinicianId: string, appointmentTime: string, timezone: string, meetingType: string, phoneNumber: string | null): Promise<any> {
        return this.axios.post(`${this.apiRoot}/scheduling/wpo`, { clinicianId, appointmentTime, timezone, meetingType, phoneNumber }, this.getHttpConfig())
    }

    getScheduledWpoAppointment(): Promise<any> {
        return this.axios.get<ApiClinician[]>(`${this.apiRoot}/scheduling/wpo/appointments`, this.getHttpConfig())
            .then(response => response.data)
    }

    cancelWpoAppointment(id: string): Promise<any> {
        return this.axios.put(`${this.apiRoot}/scheduling/wpo/cancel`, { id }, this.getHttpConfig()).then(response => response.data)
    }


    //----------------------------------- HrHub --------------------------------------------------
    getHrHubContent(): Promise<ApiDirectoryNode[]> {
        return this.axios.get<ApiDirectoryNode[]>(`${this.apiRoot}/hr-hub/`, this.getHttpConfig())
            .then(response => response.data)
    }

    searchHrHubContent(query: string): Promise<ApiDirectoryNode[]> {
        return this.axios.get<ApiDirectoryNode[]>(`${this.apiRoot}/hr-hub/search?q=${query}`, this.getHttpConfig())
            .then(response => response.data)
    }


    //----------------------------------- Teachers --------------------------------------------------

    getTeacher(id: string): Promise<ApiTeacher> {
        return this.axios.get<ApiTeacher>(`${this.apiRoot}/teacher/${id}`, this.getHttpConfig())
            .then(response => response.data)
    }

    getTeacherContent(teacherId: string): Promise<ContentItem[]> {
        return this.axios.get<ContentItem[]>(`${this.apiRoot}/teacher/content/${teacherId}`, this.getHttpConfig())
            .then(response => response.data)
    }

    getFeatureConfiguration(): Promise<any> {
        return this.axios.get<any>(`${this.apiRoot}/localization/features.json`, this.getHttpConfig())
            .then(response => response.data);
    }

    getLocale(locale: string): Promise<any> {
        return this.axios.get<any>(`${this.apiRoot}/localization/${locale}`, this.getHttpConfig())
            .then(response => response.data);
    }

    getCompanyFeatureConfiguration(companyId: number): Promise<any> {
        return this.axios.get<any>(`${this.apiRoot}/localization/companyfeatures.json?companyId=${companyId}`, this.getHttpConfig())
            .then(response => response.data);
    }

    subscribeToDailyJourney(): Promise<void> {
        return this.axios.post<any>(`${this.apiRoot}/daily-journey/subscribe`, {}, this.getHttpConfig())
            .then(response => response.data);
    }

    saveMonthlyCheckinAnswers({}){

    };

    userIsAuthenticated(): Promise<boolean> {
        return this.axios.get<any>(`${this.apiRoot}/users/is-authenticated`,{withCredentials:true})
            .then(response => response.data.state);
    }
    /**
     * @see https://axios-http.com/docs/handling_errors
     */
    errorFormatter = (error: any) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response);
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            return Promise.reject({
                errorCode: error.response.status,
                ...error.response.data
            });

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log('Error', error.request);
            return Promise.reject({
                errorCode: 500,
                error: 'Internal Server Error',
                message: 'Internal Server Error'
            });

        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message);
            return Promise.reject({
                errorCode: 500,
                error: error.message || 'Internal Application Error.',
                message: error.message || 'Internal Application Error.'
            });
        }
    };
}

export const JourneyApiClient = new ApiServiceImpl();
